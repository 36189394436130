html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F7F9FC; */
  overscroll-behavior-y: none;
}

.MuiCardHeader-action .MuiIconButton-root {
  padding: 4px;
  width: 28px;
  height: 28px;
}

body > iframe {
  pointer-events: none;
}
